.leaves {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.leaf {
  position: absolute;
  width: 30px;
  height: auto;
  border-radius: 50%;
  opacity: 0.8;
  animation: fall linear infinite;
}

@keyframes fall {
  0% {
    transform: translate(
      -50vw,
      -10vh
    ); /* Menyembunyikan daun di luar layar sebelum animasi dimulai */
  }
  100% {
    transform: translate(
      100vw,
      100vh
    ); /* Menggerakkan daun ke kanan bawah hingga di luar layar */
  }
}
