.swiper {
  width: 100%;
  height: 100%;
  color: 'black';
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
}
.swiper-slide-active > img {
  transform: scale(1);
  transition: transform 1s ease-in-out;
}
/* 
.swiper-slide-prev {
  z-index: 0;
  background: transparent;
  right: -40px;
  top: 10px;
}

.swiper-slide-next {
  z-index: 0;
  background: transparent;
  left: -40px;
  top: 10px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 30px;
} */

.swiper-pagination {
  background: #0000008a;
  padding: 10px;
  border-radius: 15px;
}

.swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-slide-prev img,
.swiper-slide-next img {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.swiper-slide > img {
  display: block;
  width: 100%;
  height: 550px;
  object-fit: cover;
}
