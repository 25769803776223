.heartbeat {
  width: 150px;
  height: 130px;
  animation: heartbeat 2s cubic-bezier(0.45, 0, 0.55, 1) infinite;
  margin: 0 auto;
}

@keyframes heartbeat {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.5);
  }
  35% {
    transform: scale(1);
  }
  45% {
    transform: scale(1.2);
  }
  65% {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(0.5);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(0.5);
  }
}
